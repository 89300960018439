<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Detalle pago de CLientes</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Detalle Pagos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>DETALLE PAGO DE CLIENTES</strong></h4>
    </div>
    <div class="grid border-bottom-2" style="background-color: #f8f9fa">
      <div class="col-6">
        <p class="mb-0">
          <strong>CLIENTE: </strong>
          <span>{{ pago_cliente.cliente_nombre }}</span>
        </p>
        <p class="mb-0">
          <strong>VENTA NRO.: </strong>
          <span>{{ pago_cliente.venta_id }}</span>
        </p>
        <p class="mb-0">
          <strong>TOTAL VENTA: </strong>
          <span style="font-weight: bold; font-size: 1.2em"
            >{{
              convertirNumeroGermanicFormat(pago_cliente.total_venta)
            }}Bs.</span
          >
        </p>
      </div>
      <div class="col-6">
        <p class="mb-0">
          <strong>FECHA DE VENTA: </strong>
          <span>{{ pago_cliente.fecha_pago }}</span>
        </p>
        <p class="mb-0">
          <strong>TOTAL PAGADO: </strong>
          <span
            style="
              color: green;
              font-weight: bold;
              font-size: 1.2em;
              margin-left: 2px;
            "
            >{{ total_pagado }}</span
          >
        </p>
        <p class="mb-0">
          <strong>SALDO: </strong>
          <!-- <span>{{ pago_cliente.saldo }}</span> -->
          <span
            v-if="pago_cliente.total_venta - parseFloat(sumarMontoTotal()) > 0"
            style="
              color: red;
              font-weight: bold;
              font-size: 1.2em;
              margin-left: 2px;
            "
            >{{
              convertirNumeroGermanicFormat(
                pago_cliente.total_venta - parseFloat(sumarMontoTotal())
              )
            }}
          </span>

          <span
            v-else
            style="
              color: green;
              font-weight: bold;
              font-size: 1.2em;
              margin-left: 2px;
            "
            >0.00</span
          >
        </p>
      </div>
    </div>
    <div class="col-12 mt-4">
      <DataTable
        ref="dt"
        :value="detalle_pagos"
        key="id"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        selectionMode="single click"
      >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="nombre_usuario" header="USUARIO" />
        <Column field="nro_cuota" header="NRO. CUOTA" />
        <Column field="fecha_pago" header="FECHA PAGO" />
        <Column field="a_cuenta_efectivo" header="EFECTIVO">
          <template #body="slotProps">
            <span>
              {{
                convertirNumeroGermanicFormat(slotProps.data.a_cuenta_efectivo)
              }}
            </span>
          </template>
        </Column>
        <Column field="a_cuenta_metodo_pago" header="TRANSFERENCIAS">
          <template #body="slotProps">
            <span>
              {{
                convertirNumeroGermanicFormat(
                  slotProps.data.a_cuenta_metodo_pago
                )
              }}
              - {{ slotProps.data.nombre_metodo_pago }}
            </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex col-12 justify-content-end">
      <Button @click="goBack" class="mr-2">
        <i class="pi pi-arrow-circle-left"></i>
        &nbsp;ATR&Aacute;S
      </Button>

      <Button
        label="IMPRIMIR"
        icon="pi pi-file-pdf"
        class="p-button-danger p-button-lg"
        @click="imprimirDetalle()"
        :loading="imprimiendo"
      />
    </div>
  </div>
</template>
<script>
import PagoClienteService from "@/service/PagoClienteService.js";
export default {
  name: "PagosProveedorDetalle",
  data() {
    return {
      detalle_pagos: [],
      detalle_pago: {},
      pago_cliente: {},
      imprimiendo: false,
    };
  },

  pagoClienteService: null,

  created() {
    this.pagoClienteService = new PagoClienteService();
  },
  computed: {
    total_pagado() {
      return this.sumarMontoTotal();
    },
    /* saldo() {
      let saldo = 0;
      saldo =
        parseFloat(this.pago_cliente.total_venta) -
        parseFloat(this.sumarMontoTotal());
        return this.convertirNumeroGermanicFormat(saldo);
    }, */
  },
  mounted() {
    this.getDetallePagos();
    this.sumarMontoTotal();
  },

  methods: {
    imprimirDetalle() {
      this.imprimiendo = true;
      this.pagoClienteService
        .imprimirDetalle(this.$route.params.id)
        .then(() => {
          this.imprimiendo = false;
        })
        .catch(() => {
          this.imprimiendo = false;
        });
    },
    sumarMontoTotal() {
      let a_cuenta_efectivo = 0;
      let a_cuenta_metodo_pago = 0;
      let total = 0;
      this.detalle_pagos.forEach((detalle) => {
        a_cuenta_efectivo += parseFloat(detalle.a_cuenta_efectivo);
        a_cuenta_metodo_pago += parseFloat(detalle.a_cuenta_metodo_pago);
      });
      total = a_cuenta_efectivo + a_cuenta_metodo_pago;
      return this.convertirNumeroGermanicFormat(total);
    },
    goBack() {
      this.$router.go(-1);
    },
    getDetallePagos() {
      this.pagoClienteService
        .getDetallePagos(this.$route.params.id)
        .then((data) => {
          this.detalle_pagos = data.detalle_pagos;
          this.detalle_pago = data.detalle_pagos[0] || {};
          this.pago_cliente = data.pago_cliente;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
};
</script>
